import React, { useState } from 'react';
import Slider from 'react-slick'; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rengok from '../assets/sonrisa.jpg';
import luz from '../assets/luz.jpg';
import negro from '../assets/negro.jpg';


function TenthSection() {



  const [selectedImage, setSelectedImage] = useState(null); // Estado para la imagen seleccionada

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc); // Actualiza la imagen seleccionada al hacer clic
  };

  const closeModal = () => {
    setSelectedImage(null); // Cierra el modal al hacer clic fuera
  };

  return (
    <section className="tenth-section">
      <Slider {...settings}>
        <div>
          <img 
            src={rengok} 
            alt="Imagen 4" 
            onClick={() => handleImageClick(rengok)} // Maneja clics en la imagen
          />
        </div>
        <div>
          <img 
            src={luz} 
            alt="Imagen 5" 
            onClick={() => handleImageClick(luz)} 
          />
        </div>
        <div>
          <img 
            src={negro} 
            alt="Imagen 6" 
            onClick={() => handleImageClick(negro)} 
          />
        </div>
      </Slider>

      {/* Modal para mostrar la imagen ampliada */}
      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Imagen ampliada" />
          </div>
        </div>
      )}
    </section>
  );
}



export default TenthSection;