import React, { useState, useRef } from 'react';
import play from '../assets/play.png'; // Importa la imagen del ícono de play

function FirstSection() {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null); // Referencia al elemento de audio

  const toggleAudio = () => {
    if (isPlaying) {
      audioRef.current.pause(); // Detiene el audio si ya está en reproducción
    } else {
      audioRef.current.play(); // Reproduce el audio si no está en reproducción
    }
    setIsPlaying(!isPlaying); // Cambia el estado de reproducción
  };

  return (
    <section className="first-section">
      {/* Botón para controlar la reproducción del audio */}
      <button 
      onClick={toggleAudio} 
      style={{
        border: "none", 
        background: "none", 
        textAlign: "center", 
        marginTop: '-20px' // Ajusta este valor para subir el botón
      }}
    >
      <img 
        src={play} 
        alt="Play" 
        style={{ width: '50px', height: '50px', cursor: 'pointer' }} 
      />
      <br />
      <span style={{ fontSize: '20px', color: '#333',}}>Nuestra canción</span> 
    </button>


      <h1 className="mi_felicidad">"Eres mi felicidad, todo mi sueño hecho realidad."</h1>
      <h1 className="save-date">SAVE <span className="highlight">our</span> DATE</h1>
      <hr className="divider" />
      <h1 className='fecha'>02.02.25</h1>

      {/* Elemento de audio que se reproducirá en bucle */}
      <audio ref={audioRef} src="/cancion.mp3" loop />
    </section>
  );
}

export default FirstSection;
