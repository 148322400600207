import React, { useState } from 'react';

function AddUsuarios() {
  const [nombres, setNombres] = useState(''); // Estado para el campo de nombres del usuario principal
  const [apellidos, setApellidos] = useState(''); // Estado para el campo de apellidos del usuario principal
  const [voletos, setVoletos] = useState(0); // Estado para el campo de boletos
  const [invitados, setInvitados] = useState([{ nombres: '', apellidos: '', asistencia: 'No' }]); // Estado para los invitados
  const [mensaje, setMensaje] = useState(''); // Estado para mostrar mensajes de éxito o error

  // Función para manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevenir el comportamiento por defecto del formulario (recarga de página)

    // Crear el objeto de datos que se enviará para el usuario principal
    const datosUsuario = {
      nombres,
      apellidos,
      voletos,
      confirmados: null, // Confirmado se agregará como "No confirmado" por defecto
    };

    try {
      // Enviar los datos del usuario principal al backend
      const responseUsuario = await fetch('https://bri-carlos.site/api/insert', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datosUsuario),
      });

      if (!responseUsuario.ok) {
        throw new Error('Error al agregar el usuario');
      }

      // Si la respuesta es exitosa, obtener el id del usuario principal
      const usuario = await responseUsuario.json();
      const usuarioId = usuario.id;

      // Enviar los datos de los invitados
      for (const invitado of invitados) {
        const datosInvitado = {
          nombres: invitado.nombres,
          apellidos: invitado.apellidos,
          asistencia: 'No', // Asistencia se agrega como "No" por defecto
          datos_id: usuarioId, // Asociamos el id del usuario principal
        };

        const responseInvitado = await fetch('https://bri-carlos.site/api/insert_invitado2', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(datosInvitado),
        });

        if (!responseInvitado.ok) {
          throw new Error('Error al agregar los invitados');
        }
      }

      setMensaje('Usuario e invitados agregados con éxito');
      // Limpiar los campos del formulario
      setNombres('');
      setApellidos('');
      setVoletos(0);
      setInvitados([{ nombres: '', apellidos: '', asistencia: 'No' }]);
    } catch (error) {
      setMensaje('Error: ' + error.message);
    }
  };

  // Función para manejar la adición de un nuevo invitado
  const handleAddInvitado = () => {
    setInvitados([...invitados, { nombres: '', apellidos: '', asistencia: 'No' }]);
  };

  // Función para manejar el cambio en los campos de los invitados
  const handleInvitadoChange = (index, field, value) => {
    const updatedInvitados = [...invitados];
    updatedInvitados[index][field] = value;
    setInvitados(updatedInvitados);
  };

  return (
    <section style={{ padding: '20px' }}>
      <h1>Agregar Usuario e Invitados</h1>

      {mensaje && <p style={{ color: mensaje.includes('Error') ? 'red' : 'green' }}>{mensaje}</p>}

      <form onSubmit={handleSubmit} style={{ maxWidth: '500px', margin: '0 auto' }}>
        {/* Formulario para el usuario principal */}
        <div style={{ marginBottom: '15px' }}>
            <h1>Usuario principal</h1>
          <label htmlFor="nombres" style={{ display: 'block', marginBottom: '5px' }}>Nombres del Usuario</label>
          <input
            type="text"
            id="nombres"
            value={nombres}
            onChange={(e) => setNombres(e.target.value)}
            required
            style={{ width: '100%', padding: '8px', fontSize: '14px' }}
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="apellidos" style={{ display: 'block', marginBottom: '5px' }}>Apellidos del Usuario</label>
          <input
            type="text"
            id="apellidos"
            value={apellidos}
            onChange={(e) => setApellidos(e.target.value)}
            required
            style={{ width: '100%', padding: '8px', fontSize: '14px' }}
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="voletos" style={{ display: 'block', marginBottom: '5px' }}>Boletos</label>
          <input
            type="number"
            id="voletos"
            value={voletos}
            onChange={(e) => setVoletos(e.target.value)}
            min="1"
            required
            style={{ width: '100%', padding: '8px', fontSize: '14px' }}
          />
        </div>

        {/* Formulario para los invitados */}
        <h3>Invitados del Usuario</h3>
        {invitados.map((invitado, index) => (
          <div key={index} style={{ marginBottom: '15px' }}>
            <h4>Invitado {index + 1}</h4>
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor={`invitado-nombres-${index}`} style={{ display: 'block' }}>Nombres</label>
              <input
                type="text"
                id={`invitado-nombres-${index}`}
                value={invitado.nombres}
                onChange={(e) => handleInvitadoChange(index, 'nombres', e.target.value)}
                required
                style={{ width: '100%', padding: '8px', fontSize: '14px' }}
              />
            </div>

            <div style={{ marginBottom: '10px' }}>
              <label htmlFor={`invitado-apellidos-${index}`} style={{ display: 'block' }}>Apellidos</label>
              <input
                type="text"
                id={`invitado-apellidos-${index}`}
                value={invitado.apellidos}
                onChange={(e) => handleInvitadoChange(index, 'apellidos', e.target.value)}
                required
                style={{ width: '100%', padding: '8px', fontSize: '14px' }}
              />
            </div>
          </div>
        ))}

        <button
          type="button"
          onClick={handleAddInvitado}
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            cursor: 'pointer',
            fontSize: '14px',
            marginBottom: '15px',
          }}
        >
          Agregar Invitado
        </button>

        <button
          type="submit"
          style={{
            backgroundColor: '#007BFF',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            cursor: 'pointer',
            fontSize: '14px',
          }}
        >
          Guardar Usuario e Invitados
        </button>
      </form>
    </section>
  );
}

export default AddUsuarios;



