import React, { useState } from 'react';
import Slider from 'react-slick'; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rengoku from '../assets/ramo.jpg';
import pastel from '../assets/pastel.jpg';
import anillo from '../assets/anillo.jpg';


function EighthSection() {
  const [selectedImage, setSelectedImage] = useState(null); // Estado para la imagen seleccionada

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc); // Actualiza la imagen seleccionada al hacer clic
  };

  const closeModal = () => {
    setSelectedImage(null); // Cierra el modal al hacer clic fuera
  };

  return (
    <section className="eighth-section">
      <Slider {...settings}>
        <div>
          <img 
            src={rengoku} 
            alt="Imagen 1" 
            onClick={() => handleImageClick(rengoku)} // Maneja clics en la imagen
          />
        </div>
        <div>
          <img 
            src={pastel} 
            alt="Imagen 2" 
            onClick={() => handleImageClick(pastel)} 
          />
        </div>
        <div>
          <img 
            src={anillo} 
            alt="Imagen 3" 
            onClick={() => handleImageClick(anillo)} 
          />
        </div>
      </Slider>

      {/* Modal para mostrar la imagen ampliada */}
      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Imagen ampliada" />
          </div>
        </div>
      )}
    </section>
  );
}

export default EighthSection;

