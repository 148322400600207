import React from 'react';


function FourthSection() {
  return (
    <section className="fourth-section">
        

        {/* Botón de Google Maps */}
        <a
          href="https://www.google.com/maps/place/Campo+Mágico/@20.7644413,-103.5370686,17z/data=!3m1!4b1!4m6!3m5!1s0x8428a7f301e4fbb9:0x57b905536278f832!8m2!3d20.7644413!4d-103.5344937!16s%2Fg%2F11cncrfnv5?hl=es-mx&entry=ttu&g_ep=EgoyMDI0MTEyNC4xIKXMDSoASAFQAw%3D%3D" // Reemplaza con la URL de tu ubicación en Google Maps
          target="_blank" // Abre la URL en una nueva pestaña
          rel="noopener noreferrer" // Seguridad para enlaces externos
          className="boton-maps"
        >
          Google Maps
        </a>
    </section>
  );
}

export default FourthSection;