import React, { useState, useEffect } from "react";
import './database.css';

function DatabaseViewer() {
  const [datos, setDatos] = useState([]); // Estado para almacenar los datos
  const [error, setError] = useState(null); // Estado para manejar errores
  const [invitados, setInvitados] = useState(null); // Estado para almacenar los invitados de un usuario
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(null); // Estado para almacenar el ID del usuario seleccionado

  // Función para obtener los datos del servidor Flask
  const fetchDatos = async () => {
    try {
      const response = await fetch("https://bri-carlos.site/api/get"); // URL del endpoint en Flask
      if (!response.ok) {
        throw new Error("Error al obtener los datos");
      }
      const data = await response.json();
      setDatos(data);
    } catch (err) {
      setError(err.message);
    }
  };

  // Función para obtener los invitados asociados a un usuario
  const fetchInvitados = async (id) => {
    try {
      const response = await fetch(`https://bri-carlos.site/api/get_invitados/${id}`);
      if (!response.ok) {
        throw new Error("Error al obtener los invitados");
      }
      const data = await response.json();
      setInvitados(data);
    } catch (error) {
      console.error(error);
    }
  };

  // Llama a la función fetchDatos al montar el componente
  useEffect(() => {
    fetchDatos();
  }, []);

  // Función para manejar el clic en el nombre del usuario
  const handleUsuarioClick = (id) => {
    setUsuarioSeleccionado(id);
    fetchInvitados(id); // Cargar los invitados cuando se selecciona un usuario
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Datos de la Base de Datos</h1>
      {error && <p style={{ color: "red" }}>Error: {error}</p>}
      {!error && datos.length === 0 && <p>No hay datos disponibles.</p>}
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={tableHeaderStyle}>ID</th>
            <th style={tableHeaderStyle}>Nombres</th>
            <th style={tableHeaderStyle}>Apellidos</th>
            <th style={tableHeaderStyle}>Voletos</th>
            <th style={tableHeaderStyle}>Confirmados</th>
            <th style={tableHeaderStyle}>Enlace</th> {/* Nueva columna para mostrar la URL */}
          </tr>
        </thead>
        <tbody>
          {datos.map((dato) => (
            <tr key={dato.id}>
              <td style={tableCellStyle}>{dato.id}</td>
              <td
                style={tableCellStyle}
                onClick={() => handleUsuarioClick(dato.id)}
                className="clickable hoverable"
              >
                {dato.nombres}
              </td>
              <td style={tableCellStyle}>{dato.apellidos}</td>
              <td style={tableCellStyle}>{dato.voletos}</td>
              <td style={tableCellStyle}>
                {dato.confirmados === null ? "No confirmado" : dato.confirmados}
              </td>
              <td style={tableCellStyle}>
                {/* Mostrar la URL personalizada como texto */}
                <input
                  type="text"
                  value={`https://bri-carlos.site/?id=${dato.id}`} // URL con el ID
                  readOnly
                  style={{
                    width: "100%",
                    padding: "5px",
                    fontSize: "14px",
                    backgroundColor: "#f2f2f2",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Mostrar los invitados si un usuario ha sido seleccionado */}
      {usuarioSeleccionado && invitados && (
        <div style={{ marginTop: "20px" }}>
          <h2>Invitados para el Usuario {usuarioSeleccionado}</h2>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Nombre</th>
                <th style={tableHeaderStyle}>Asistencia</th>
              </tr>
            </thead>
            <tbody>
              {invitados.map((invitado) => (
                <tr key={invitado.id}>
                  <td style={tableCellStyle}>{invitado.nombres} {invitado.apellidos}</td>
                  <td style={tableCellStyle}>{invitado.asistencia}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

const tableHeaderStyle = {
  border: "1px solid black",
  padding: "8px",
  backgroundColor: "#f2f2f2",
};

const tableCellStyle = {
  border: "1px solid black",
  padding: "8px",
};

export default DatabaseViewer;

