import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import '../App.css';
import computadoraImagen from '../assets/imagenComputadoras.png';

function Confirmar() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id"); // Obtén el ID desde la URL
  const [datos, setDatos] = useState(null); // Estado para almacenar los datos del usuario
  const [invitados, setInvitados] = useState([]); // Estado para almacenar los invitados
  const [seleccionados, setSeleccionados] = useState([]); // Estado para almacenar los invitados seleccionados

  // Función para obtener los datos del usuario
  const fetchUsuario = async () => {
    try {
      const response = await fetch(`https://bri-carlos.site/api/get/${id}`);
      if (!response.ok) {
        throw new Error("Error al obtener los datos del usuario");
      }
      const data = await response.json();
      setDatos(data);
    } catch (error) {
      console.error(error);
    }
  };

  // Función para obtener los invitados asociados al usuario
  const fetchInvitados = async () => {
    try {
      const response = await fetch(`https://bri-carlos.site/api/get_invitados/${id}`);
      if (!response.ok) {
        throw new Error("Error al obtener los invitados");
      }
      const data = await response.json();
      setInvitados(data);
    } catch (error) {
      console.error(error);
    }
  };

  // Función para manejar el cambio en el estado de los checkboxes
  const handleCheckboxChange = (invitadoId) => {
    setSeleccionados((prevSeleccionados) =>
      prevSeleccionados.includes(invitadoId)
        ? prevSeleccionados.filter((id) => id !== invitadoId) // Desmarcar el checkbox
        : [...prevSeleccionados, invitadoId] // Marcar el checkbox
    );
  };

  // Función para confirmar la asistencia de los invitados seleccionados
  const confirmarAsistencia = async () => {
    try {
      const response = await fetch(`https://bri-carlos.site/api/confirmar_asistencia/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ invitados: seleccionados }), // Enviar los IDs de los invitados seleccionados
      });
      if (!response.ok) {
        throw new Error("Error al confirmar la asistencia");
      }
      const data = await response.json();
      alert(data.message); // Muestra un mensaje de confirmación
      fetchUsuario(); // Vuelve a obtener los datos para reflejar la actualización
      fetchInvitados(); // Vuelve a cargar los invitados actualizados
    } catch (error) {
      console.error(error);
    }
  };
  

  useEffect(() => {
    if (id) {
      fetchUsuario();
      fetchInvitados(); // Cargar los invitados al cargar el componente
    }
  }, [id]);












  
  return (
    <div>
    <img className="desktop-image" src={computadoraImagen} alt="Acceder desde móviles" />
    <div className="extra-seccion hide">
      {/* Mostrar esta imagen solo en pantallas grandes */}
      
      {datos ? (
        <div className="hide">
        
          

          <h2>Invitados</h2>
          {invitados.length > 0 ? (
            <ul className="custom-list">
              {invitados.map((invitado) => (
                <li key={invitado.id} className="list-item">
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    checked={seleccionados.includes(invitado.id)}
                    onChange={() => handleCheckboxChange(invitado.id)}
                  />
                  {invitado.nombres} {invitado.apellidos} - Asistencia: {invitado.asistencia}
                </li>
              ))}
            </ul>
          ) : (
            <p>No hay invitados para este usuario.</p>
          )}
        <button onClick={confirmarAsistencia} className="botonsito">Confirmar Asistencia</button>
        </div>
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
    </div>
  );
}

export default Confirmar;
