import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import PaginaP from './components/PaginaP';
import DatabaseViewer from './components/DatabaseViewer';
import Confirmar from './components/Confirmar';
import AddUusarios from './components/AddUsuarios';
import Eliminar from './components/Eliminar';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PaginaP />} />
        <Route path="/control935" element={<DatabaseViewer />} />
        <Route path="/confirmar" element={<Confirmar />} />
        <Route path="/agregar935" element={<AddUusarios />} />
        <Route path="/eliminar935" element={<Eliminar />} />
        
      </Routes>
    </Router>
  );
}

export default App;