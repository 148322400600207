import React, { useState, useEffect } from 'react';


const CountdownTimer = ({ targetDate, className }) => {
  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        clearInterval(intervalId);
        setTimeLeft({});
      } else {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        });
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  return (
    <div className={className}> {/* Aquí aplicas la clase */}
  {timeLeft.days !== undefined ? (
    <div className="contador-grid">
    <div className="contador-item">
      <span className="diasG">{timeLeft.days.toString().padStart(2, '0')}</span>
      <span className="textoContador dias">DIAS</span>
    </div>
    <div className="contador-item">
      <span className="horasG">{timeLeft.hours.toString().padStart(2, '0')}</span>
      <span className="textoContador horas">HORAS</span>
    </div>
    <div className="contador-item">
      <span className="minutosG">{timeLeft.minutes.toString().padStart(2, '0')}</span>
      <span className="textoContador minutos">MINUTOS</span>
    </div>
  </div>
  ) : (
    <h2>¡El gran día ha llegado!</h2>
  )}
</div>

  );
};

export default CountdownTimer;

