import React, { useState, useEffect } from 'react';

function Eliminar() {
  const [datos, setDatos] = useState([]);

  // Función para obtener los registros
  const fetchDatos = async () => {
    try {
      const response = await fetch('https://bri-carlos.site/api/get');
      const data = await response.json();
      setDatos(data);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  // Función para eliminar un registro
  const eliminarDato = async (id) => {
    try {
      const response = await fetch(`https://bri-carlos.site/api/delete_user/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        alert('Registro eliminado correctamente.');
        // Actualiza la lista de datos
        fetchDatos();
      } else {
        alert('Error al eliminar el registro.');
      }
    } catch (error) {
      console.error('Error al eliminar el registro:', error);
    }
  };

  // Cargar los datos al montar el componente
  useEffect(() => {
    fetchDatos();
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      <h1>Eliminar</h1>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={tableHeaderStyle}>ID</th>
            <th style={tableHeaderStyle}>Nombres</th>
            <th style={tableHeaderStyle}>Apellidos</th>
            <th style={tableHeaderStyle}>Vóletos</th>
            <th style={tableHeaderStyle}>Confirmados</th>
            <th style={tableHeaderStyle}>Acción</th>
          </tr>
        </thead>
        <tbody>
          {datos.map((dato) => (
            <tr key={dato.id}>
              <td style={tableCellStyle}>{dato.id}</td>
              <td style={tableCellStyle}>{dato.nombres}</td>
              <td style={tableCellStyle}>{dato.apellidos}</td>
              <td style={tableCellStyle}>{dato.voletos}</td>
              <td style={tableCellStyle}>
                {dato.confirmados === null ? "No confirmado" : dato.confirmados}
              </td>
              <td style={tableCellStyle}>
                <button 
                  onClick={() => eliminarDato(dato.id)} 
                  style={deleteButtonStyle}
                >
                  Eliminar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

// Estilos para la tabla y celdas
const tableHeaderStyle = {
  border: '1px solid black',
  padding: '8px',
  backgroundColor: '#f2f2f2',
};

const tableCellStyle = {
  border: '1px solid black',
  padding: '8px',
};

const deleteButtonStyle = {
  padding: '5px 10px',
  backgroundColor: '#dc3545',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

export default Eliminar;
