import React from 'react';


function EleventhSection() {
  return (
    <section className="eleventh-section">
      
      <a
          href="http://192.168.100.33:3000/?id=${datos.apellidos}" // Reemplaza con la URL de tu ubicación en Google Maps
          className="boton-confirmar"
        >
          Confirmar
        </a>
        <p className="dondequiera">"Dondequiera que vayas, iré; dondequiera que vivas, viviré. Tu pueblo será mi pueblo, y tu Dios será mi Dios." Rut 1:16</p>
    </section>
  );
}

export default EleventhSection;