import React from 'react';


function NinethSection() {
  return (
    <section className="nineth-section hide">
      <p className="su_asistencia">Su asistencia a nuestra boda será regalo suficiente. Pero si desean obsequiarnos algo, pueden hacerlo de las siguientes maneras:</p>
      <hr className="divider2" />
      <p className="efectivo">Efectivo</p>
      <p className="colocando">Colocando su apoyo en un sobre y depositándolo el día de la boda en el buzón que encontrarán en la recepción.</p>
      <hr className="divider2" />
      <p className="mesa">Mesa de regalos Liverpool  NÚMERO DE EVENTO: 51455647</p>
      <a
          href="https://mesaderegalos.liverpool.com.mx/milistaderegalos/51455647" // Reemplaza con la URL de tu ubicación en Google Maps
          target="_blank" // Abre la URL en una nueva pestaña
          rel="noopener noreferrer" // Seguridad para enlaces externos
          className="boton-regalos"
        >
          Liverpool
        </a>
        <p className="mesa">Mesa de regalos Amazon</p>

        <a
          href="https://www.amazon.com.mx/wedding/share/bri-carlosporsiempre " // Reemplaza con la URL de tu ubicación en Google Maps
          target="_blank" // Abre la URL en una nueva pestaña
          rel="noopener noreferrer" // Seguridad para enlaces externos
          className="boton-regalos"
        >
          Amazon
        </a>
        <p className="mesa">¡Muchas Gracias!</p>
   </section>
  );
}

export default NinethSection;