import React, { useState } from 'react';
import Corneta from '../assets/rengoku.png';
import periodico from '../assets/periodico.jpg'

function FivethSection() {
  const [modalImage, setModalImage] = useState(null);

  const openModal = (image) => {
    setModalImage(image);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  return (
    <section className="row1 fiveth-section">
      <div
        className="fondoImagen1"
        onClick={() => openModal(Corneta)}
      ></div>
      <div
        className="fondoImagen2"
        onClick={() => openModal(periodico)}
      ></div>

      {modalImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <img src={modalImage} alt="Modal" />
          </div>
        </div>
      )}
    </section>
  );
}

export default FivethSection;