import React, { useState } from 'react';
import besor from '../assets/besoR.jpg';
import mirada from '../assets/mirada.jpg';
import saludo from '../assets/saludo.jpg';

function SixthSection() {
  const [modalImage, setModalImage] = useState(null);

  const openModal = (image) => {
    setModalImage(image);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  return (
    <div className="grid-container">
      <div
        className="image1"
        onClick={() => openModal(besor)}
      ></div>
      <div
        className="image2"
        onClick={() => openModal(mirada)}
      ></div>
      <div
        className="image3"
        onClick={() => openModal(saludo)}
      ></div>

      {modalImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <img src={modalImage} alt="Modal" />
          </div>
        </div>
      )}
    </div>
  );
}

export default SixthSection;
