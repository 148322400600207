import React from 'react';
import RingImage from '../assets/ring.png'; 
import CopaImage from '../assets/copa.png';
import ChurchImage from '../assets/church.png';
import Pollo from '../assets/pollo.png';
import Dance from '../assets/dance.png';
import Corneta from '../assets/corneta.png';


function ThirdSection() {
  return (
    <section className="third-section">
      <h1 className="texto5">NUESTRO DIA ESPECIAL</h1>

        <div className="icon-container church-container">
        <img className="church" src={ChurchImage} alt="Iglesia" />
        <h1 className="texto8">5:00<span className="texto6_5">PM</span></h1>
        <h3 className="textsub6">Si Acepto...</h3>
        </div>

        

        <div className="icon-container copa-container">
        <img className="copa" src={CopaImage} alt="Copa" />
        <h1 className="texto7">5:50<span className="texto6_5">PM</span></h1>
        <h3 className="textsub6">Cocktail Hour</h3>
        </div>

        <div className="icon-container dance-container">
        <img className="dance" src={Dance} alt="Dance" />
        <h1 className="texto7">6:30<span className="texto6_5">PM</span></h1>
        <h3 className="textsub6">Entrada de los<br></br> novios</h3>
        </div>

        <div className="icon-container pollo-container">
        <img className="pollo" src={Pollo} alt="Pollo" />
        <h1 className="texto7">7:10<span className="texto6_5">PM</span></h1>
        <h3 className="textsub6">Buen Provecho</h3>
        </div>

        <div className="icon-container corneta-container">
        <img className="corneta" src={Corneta} alt="Corneta" />
        <h1 className="texto7">8:10<span className="texto6_5">PM</span></h1>
        <h3 className="textsub6">A Bailar!</h3>
        </div>
    </section>
  );
}

export default ThirdSection;