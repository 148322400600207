import React from 'react';
import code from '../assets/Dresscode.png';


function SeventhSection() {
  return (
    <section className="seventh-section">
        <h1 className="vestimenta">VESTIMENTA</h1>
          <p className="texto16">Etiqueta</p>
          <p className="texto18">Evitar colores blanco, beige, rosa palido y gama de colores pasteles</p>
          <img className="code" src={code} alt="" />
          <p className="no_ninos">NO NIÑOS</p>
          <p className="texto18">Por cuestiones de seguridad y de la logística de nuestra boda, no se aceptará el ingreso a niños.</p>
      
    </section>
  );
}

export default SeventhSection;