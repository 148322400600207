import React from 'react';
import CountdownTimer from './CountdownTimer';


function SecondSection() {

  const weddingDate = new Date(2025, 1, 3, 0, 0, 0);
  
  return (
    <section className="second-section">

      <div>
        
      </div>
      <div className="fondoTranslucido">

        <CountdownTimer targetDate={weddingDate} className='' />

      </div>


      <div className="fondoTranslucido2">

          <p className='textoN'>P  a  r  a     n  u  e  s  t  r  a     u  n  i  ó  n</p>

      </div>

      <p className='con_imenso_amor'>Con inmenso amor y alegria en nuestros corazones, queremos compartir con ustedes el inicio de nuestro viaje juntos en el sagrado vínculo del matrimonio, un momento especial que marcará el comienzo de una nueva etapa en nuestras vidas. </p>

    </section>
  );
}

export default SecondSection;