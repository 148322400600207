import React, { useState, useEffect } from "react";
import { data, useSearchParams } from "react-router-dom";

import '../App.css';
import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import FourthSection from './FourthSection';
import FivethSection from './FivethSection';
import SixthSecion from './SixthSection';
import SeventhSection from './SeventhSection';
import EighthSection from './EighthSection';
import NinethSection from './Nineth';
import TenthSection from './TenthSection';
import EleventhSection from './ElevethSection';
import computadoraImagen from '../assets/imagenComputadoras.png';




function PaginaP() {
  const [searchParams] = useSearchParams();
  const [datos, setDatos] = useState(null);
  const [error, setError] = useState(null);

  const id = searchParams.get("id"); // Obtener el parámetro "id" de la URL

  // Función para obtener los datos de la persona desde la base de datos
  const fetchDatos = async () => {
    try {
      const response = await fetch(`https://bri-carlos.site/api/get/${id}`); // Endpoint con el ID
      if (!response.ok) {
        throw new Error("Error al obtener los datos");
      }
      const data = await response.json();
      setDatos(data);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    if (id) {
      fetchDatos();
    }
  }, [id]);

  if (!id) {
    return <p>Error: No tiene una invitacion valida.</p>;
  }

  if (error) {
    return <p style={{ color: "red" }}>Error: {error}</p>;
  }

  if (!datos) {
    return <p>Cargando datos...</p>;
  }



  return (
    <div className="App">
        {/* Mostrar esta imagen solo en pantallas grandes */}
      <img className="desktop-image" src={computadoraImagen} alt="Acceder desde móviles" />
                <FirstSection />
                <SecondSection />
                <ThirdSection />
                <FourthSection />
                <FivethSection />
                <SixthSecion />
                <SeventhSection />
                <EighthSection />
                <NinethSection />
                <TenthSection />
                <section className="eleventh-section">
      
                  <a
                    href={`/confirmar?id=${id}`} // Cambia `data.id` por `datos.id`
                    className="boton-confirmar"
                  >
                    Confirmar
                  </a>
                  <p className="dondequiera">"Dondequiera que vayas, iré; dondequiera que vivas, viviré. Tu pueblo será mi pueblo, y tu Dios será mi Dios." Rut 1:16</p>
                </section>
                
    </div>
  );
}

export default PaginaP;